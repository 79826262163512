@media only screen and (min-width: 400px){
    .presentation{

        &__title{
            font-size: $h1-ultra-size;
        }
    }
}

@media only screen and (min-width: $breakpoint-mobile){
    .wrapper{
        width: 80%;
    }

    .presentation{

        &__content{
            width: 80%;
        }
    
        &__title{
            font-size: $h1-giga-size;
        }

        &__letter{
            letter-spacing: -10px;
        }

        &__btn{
            position: absolute;
            top: 2%; right: 2%;
        }
    }
    
    .softskills{

        &__title{
            margin: $space-h1 0 $space-h3;
        }
    }

    .skills{
        
        &__title{
            margin: $space-h1 0 $space-h3;

            line-height: $line-height;
        }
    }

    .projects{

        &__wrapper{
            width: 80%;
        }

        &__description{
            margin: $space-h2 0 0;
        }
    }

    .contact{
    
        &__title{
            font-size: $h1-ultra-size;
        }

        &__text{
            width: 80%;
        }
    }
    
    .footer{
    
        &__content{
            flex-direction: row;
            gap: $space-h1;
        }
        
        &__el a{
            padding: 0 5px;
        }
        
    }

    .icanvas{

        &__wrapper{
            width: 80%;
        }
    }

}

@media only screen and (min-width: 650px){
    .icanvas{
        &__title{
            line-height: $line-height;
        }
    }
}

@media only screen and (min-width: 725px){
    .contact{

        &__title{
            line-height: $line-height;
        }
    }
}

@media only screen and (min-width: $breakpoint-tablet){
    .wrapper{
        width: 55%;
    }

    .presentation{

        &__content{
            width: 55%;
        }
    }

    .projects{

        &__wrapper{
            width: 55%;
        }
    }

    .contact{

        &__text{
            width: 55%;
        }
    }

    .network{

        &__list{
            display: block;
        }
    }

    .icanvas{

        &__wrapper{
            width: 65%;
        }
    }

    .credits{

        &__wrapper{
            max-width: 75%;
        }
    }

}

@media only screen and (min-width: $breakpoint-desktop){
    .wrapper{
        width: 100%;
    }

    .presentation{

        &__text{
            max-width: 65%;
            margin: 0 auto;
        }

        &__btn{
            position: absolute;
            top: 4%; right: 3%;
        }

        &__svg{
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%; left: 50%;
            z-index: -1;

            display: block;
            width: 76%;     
        }

        &__path{
            fill: none;
            stroke: #faac32;
            stroke-miterlimit: 100;
            stroke-width: 2px;

            stroke-dasharray: 10000;
            stroke-dashoffset: 10000;
            display: block;
            animation: dash 7s linear forwards;
        }

        @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
        }
    }

    .softskills{
        position: relative;

        &__line{
            position: absolute;
            top: 0; left: 5%;

            height: 100%;

            display: block;
        }

        &__path{
            stroke-dasharray: 10000;
            stroke-dashoffset: 10000;
            display: block;
            animation: dash 10s linear forwards;
    
            fill: none;
            stroke: #faac32;
            stroke-miterlimit: 100;
            stroke-width: 2px;
        }

        &__dots{
            display: block;

            width: 276.75px;
            height: 108px;

            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            position: absolute;
            bottom: 15%; right: 10%;
        }
    
        &__container{
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            column-gap: $space-p-mini;

            padding-bottom: 0;
        }
    
        &__grid{
            grid-row: 2;
            grid-column: 2/5;
        }
        
        &__title{
            grid-row: 1;
            grid-column: 2/5;
            font-size: $h1-size;
        }
    
        &__list{
            grid-row: 3;
            grid-column: 2/5;
            margin-bottom: $space-h1;
        }

        
    }

    .skills{
        position: relative;

        &__line{
            position: absolute;
            top: 0; left: 12%;

            height: 100%;

            display: block;
        }

        &__path{
            stroke-dasharray: 10000;
            stroke-dashoffset: 10000;
            display: block;
            animation: dash 10s linear forwards;
    
            fill: none;
            stroke: #faac32;
            stroke-miterlimit: 100;
            stroke-width: 2px;
        }

        &__dots{
            display: block;

            width: 276.75px;
            height: 108px;

            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;

            position: absolute;
            bottom: 15%; left: 5%;
        }
    
        &__container{
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            column-gap: $space-p-mini;

            padding-bottom: $space-h2;
        }
    
        &__grid{
            grid-row: 2;
            grid-column: 3/7;
            margin-bottom: 0;
        }
        
        &__title{
            grid-row: 1;
            grid-column: 3/7;
        }
    
        &__list{
            flex-wrap: nowrap;
            grid-row: 3;
            grid-column: 3/9;
            margin-top: $space-h3;
        }

        &__el{
            flex: 1;
        }
    }

    .projects{
        width: 100%;
        height: 100vh;
    
        overflow-x: hidden;
        position: relative;

        &__svg{
            position: absolute;
            z-index: 0;

            display: block;

            &--first{
                top: $space-h2; left: 0; right: 0; 
            }

            &--second{
                top: $space-h3; left: 0; right: 0;
            }

            &--third{
                top: $space-p; left: 0; right: 0;
            }

            &--fourth{
                top: $space-p; left: 0; right: 0;
            }

            &--fifth{
                top: $space-p; left: 0; right: 0;
            }
            
        }

        &__path{
            fill: none;
            stroke: #faac32;
            stroke-miterlimit: 100;
            stroke-width: 2px;

            stroke-dasharray: 10000;
            stroke-dashoffset: 10000;
            animation: dash 7s linear forwards;
        }

        @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
        }

        &__indicator{
            position: absolute;
            bottom: 5%; right: 9%;

            display: flex;
            gap: 10px;
        }

        &__number{
            position: absolute;
            bottom: 100%; right: 0;
            color: white;

            &--size{
                font-size: $h3-size;
            }
        }

        &__indicator-bar{
            width: 50px;
            height: 4px;
            background-color: $light-grey;

            &--active{
                background-color: $complementary-color;
            }
        }

        &__slider{
            display: flex;
            flex-wrap: nowrap;

            width: 500vw;
            overflow: hidden;
        }

        &__el{
            overflow: hidden;
            height: 100vh;
            width: 100%;

            border-right: 2px solid $complementary-color;
            border-bottom: none;
        }

        &__projet{
            flex-direction: row;
            gap: $space-h2;

            margin-top: $space-h1;
        }

        &__wrapper{
            width: 75%; //! IMPORTANT // 82% est opti ! 88 ?
        }

        
        &__description{
            margin: $space-h1 0;
            flex: 0 0 46%;
        }

        &__secondary-title{
            font-size: $h1-giga-size;
        }

        &__assets{
            flex: 0 0 54%;
        }

        &__img{
            display: block;

            position: relative;
            z-index: 2;

            margin-top: 0;
        }
    }

    .technologies{
        margin: calc($space-p/2) 0 0;
    }

    .contact{
        &__text{
            width: 35%;
        }
    }

    .icanvas{

        &__wrapper{
            width: 75%;
        }
    
        &__title{
            margin: $space-h3 0 $space-p;
        }
    
        &__text{
            width: 55%;
        }
    }

    .credits{
        &__wrapper{
            max-width: 70%;
        }
    }

}

@media only screen and (min-width: $breakpoint-desktop) and (max-height: 900px){
    .softskills{
    
        &__list{
            margin-bottom: $space-h2;
        }
    }

    .skills{

        &__container{
            grid-template-columns: repeat(11, 1fr);
        }
        
        &__grid{
            grid-column: 3/9;
        }
        
        &__title{
            grid-column: 3/9;
        }
    
        &__list{
            grid-column: 3/11;
        }

        &__line{
            left: 9%;
        }
    }

    .projects{

        &__projet{
            margin: $space-h3 auto 0;
            gap: $space-p;
        }

        &__description{
            margin: $space-h1 $space-h3;
        }
    }
}

@media only screen and (min-width: $breakpoint-desktop) and (max-height: 750px){
    .projects{

        &__description{
            margin: $space-h3;
        }
    }
}

@media only screen and (min-width: 1845px){
    .presentation{

        &__text{
            width: 90%;
        }
    }
}

@media only screen and (min-width: 1921px){
    .presentation{

        &__svg{ 
            width: 100%;
        }
    }

    .softskills{
        &__grid{
            grid-row: 2;
            grid-column: 2/4;
        }
    }

    .skills{
        &__grid{
            grid-row: 2;
            grid-column: 3/6;
        }
    }

    .projects{

        &__projet{
            margin-top: $space-giga-h1;
        }
    }

    .contact{

        &__text{
            width: 23%;
        }
    }

    .icanvas{

        &__text{
            width: 40%;
        }
    }

}
