$p-mini-size: 13px;
$p-size: 18px;
$h3-size: 25px;
$h2-size: 36px;
$h1-size: 51px;
$h1-ultra-size: 72px;
$h1-giga-size: 102px;

$space-p-mini: 26px;
$space-p: 36px;
$space-h3: 50px;
$space-h2: 72px;
$space-h1: 102px;
$space-ultra-h1: 144px;
$space-giga-h1: 204px;

$line-height: 150%;

$title-font: "futura-pt", sans-serif;
$text-font: "proxima-nova", sans-serif;

$light-grey: #FAFAFA;
$darker-grey: #212121;

$blue: #32a2fb;
$complementary-color: #FAAC32;
$darker-blue: #060D19;

$link-color: #FAAC32;
$link-visited-color: #faa419;
$link-hover-color: #fad496;
$link-active-color: #d3aa68;

$dark-blue:  hsl(218, 61%, 26%);

$breakpoint-mobile: 640px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop: 1440px;
$breakpoint-desktop-large: 1921px;